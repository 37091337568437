.page_loader {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	border: 5px solid #222222;
	border-top-color: #F0F0F0;
	box-sizing: border-box;
	background: transparent;
	animation: loading 1s linear infinite;
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}
	0% {
		transform: rotate(360deg);
	}
}
.btn-primary {
	color: #eef3ff !important;
	background-color: #E33BC3 !important;
	border-color: #E33BC3 !important;
	outline: #E33BC3 !important;
}
.btn-primary:hover {
	color: #eef3ff !important;
	background-color: #cd26ae !important;
	border-color: #cd26ae !important;
}
.btn-primary:focus {
	/* box-shadow: 0 0 0 0.25rem #E33BC3 !important; */
}
.btn-outline {
	border-color: #E33BC3;
}
.btn-close {
	color: #f0f0f0 !important;
	background-color: #222222 !important;
	border-color: #222222 !important;
}
.btn-close:focus {
	border:1px solid #222222 !important;
}
.form-control:focus {
	border-color: #222222 !important;
}
.sidebar-link:hover .svg-icon {
	color: #E33BC3 !important;
}
.sidebar-item.active > .sidebar-link .svg-icon {
	color: #E33BC3 !important;
}
.sidebar-item.active > .sidebar-link {
	border-left: 2px solid #E33BC3;
}
.breadcrumb-item a {
	color: #222222 !important;
}
.breadcrumb-item a:hover {
	color: #222222 !important;
}
.form-select:focus {
	/* border-color: #E33BC3; */
}
.form-check-input:checked {
	background-color: #E33BC3;
	border-color: #E33BC3;
}
.form-check-input:focus {
	box-shadow: 0 0 0 0.25rem #E33BC3 !important;
	border-color: #E33BC3 !important;
}
.text-primary {
	color: #E33BC3 !important;
}

.dropdown-menu-item:hover {
	background-color: #222222;
}

.bg-success {
	background-color: #46a35b !important;
}

.bg-danger {
	background-color: #bd5357 !important;
}

.text-danger {
	color: #bd5357 !important;
}

.bg-warning {
	background-color: #e3ae11a1 !important;
}

.dropdown-menu-button {
	height: "20px";
}

.dropdown-menu-button:focus {
	border: none;
	outline: none;
	box-shadow: none !important;
}

.form-field {
	/* margin-bottom: 5px !important; */
}

.validation-message {
	font-size: 14px !important;
}

.login-page .info {
	background: #000 !important;
}
.table {
	color: #222222 !important;
}

.table-hover > tbody > tr:hover {
	color: #E33BC3!important;
}
body {
	color: #eef3ff !important;
}
.breadcrumb-item.active {
	color: #eef3ff;
}
nav#sidebar {
	color: #eef3ff !important;
}
.sidebar-link {
	color: #E0D8DE !important;
}
.form-label {
	color: #222222 !important;
}
.quiz-view {
	color: #8a8d93;
}
.quiz-description {
	text-align: justify;
}
.quiz-header {
	color: #eef3ff !important;
}
.table-container {
	overflow-x: auto;
}
.btn-danger {
	color: #fff;
	background-color: #bd5357;
	border-color: #bd5357;
	outline: #bd5357 !important;
}
.btn-danger:hover {
	background-color: #bd5357;
	border-color: #bd5357;
	outline: #bd5357 !important;
}
.btn-danger:focus {
	background-color: #bd5357;
	border-color: #bd5357;
	outline: #bd5357 !important;
}

.tableFixHead {
	overflow-y: auto; 
	max-height: 550px;
}
.tableFixHead thead th {
	position: sticky;
	top: 0px; 
}
table {
	border-collapse: collapse;
	width: 100%;
}
table thead tr th,
table tbody tr td {
    color: #222222 !important;
}
.sidebar-item.active ul .sidebar-link {
    border-left: 2px solid #E33BC3 !important;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #4c4c4c !important;
    opacity: 1;
}
.highlightOption {
    background: "pink" !important;
    color: #fff;
}
.form-control, .form-select {
	font-weight: 300 !important;
	color: #222222 ;
}
.custom-stepper a, .custom-stepper span {
	line-height: 31px !important;
}
.btn-dark:disabled {
	color: #eef3ff !important;
}

/* color: #eef3ff !important */
.imgView {
	display: flex;
	align-items: center;
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    text-align: center;
    border: #dee2e6;
    line-height: 3.5rem;
	justify-content: center;
}
.imgView img {
	border-radius: 4px;
}
.sample-file-btn {
	color: #fff !important;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}
.sample-file-btn:hover {
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}
.icon_down_dir::before {
	content: none !important;
}
.form-select:disabled {
    background-color: #222222;
}

.fontAptos {
    font-family: 'Aptos';
    font-style: normal;
}
.login-btn{
	background-color: #E33BC3;
	color: #fff;
}
.login-btn:hover{
	background-color: #cd26ae;
	color: #fff;
}
.nav-tabs .nav-link.active {
	color: #E0D8DE; 
    background-color: #383838;
    border-color: #383838;
}
.nav-link {
	color: #E33BC3;
}

.nav-link:hover {
	color: #E33BC3;
}

/* Dashboard chart blur css */

.settingAbsText {
    position: absolute;
    width: 100%;
    left: 0;
	
    justify-content:center ;
    z-index: 9;
    padding: 30px;
    font-size: 18px;
    font-weight: 700;
    color: #202020;
    display: flex;
    align-items: center;
    height: 100%;
    
}
.Custome-tooltip {
	background-color: #000;
	border-radius: 17px;
	padding: 10px 0px;
}

@media (min-width: 550px) {
	.Custome-tooltip {
		width: 500px;
	}
}

.Custome-tooltip .custom-div {
	min-width: 480px;
}

@media (max-width: 550px) {
	.Custome-tooltip .custom-div {
		min-width: auto;
		width: auto;
	}
}

.customBox-blur {
	height: 500px;
	filter: blur(8px);
	pointer-events: none;
	user-select: none;
}
@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.custom-skeleton {
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #f7eadf;
    background: linear-gradient(to right, #cfcfcf 8%, #dddddd 18%, #cfcfcf 33%);
    background-size: 800px 104px;
    height: 100px;
    position: relative;
    border-radius: 5px;
}

/* Table pagination colors */
.btnPreviousEnabled {
    background-color: #222222 !important;
    color: #fff;
    border: transparent !important;
}
.btnPreviousDisabled {
    background-color: #222222 !important;
    color: #fff;
    border: transparent !important;
}
.btnNextEnabled {
    background-color: #E33BC3 !important;
    color: #fff;
    border: transparent !important;
}
.btnNextDisabled {
    background-color: #E33BC3 !important;
    color: #fff;
    border: transparent !important;
}
.btnNextEnabled, .btnNextDisabled:hover {
    background-color: #cd26ae;
    border-color: #cd26ae;
}

.btnPreviousEnabled, .btnPreviousDisabled {
    background-color: rgba(64, 64, 64, 0.8) !important;
    border-color: rgba(64, 64, 64, 0.8) !important;
}


/* userInfoCard.css */
.userInfoCard {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
  }
  
  .imageColumn {
    padding-right: 0;
    width: 15%;
    display: flex;
    justify-content: start;
  }
  
  .userInfoImage {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .detailsColumn {
    padding-left: 10px;
    display: flex;
    justify-content: start;
  }
  
  .cardBodyCustom {
    padding: 0 1rem;
  }
  
  .cardTitleCustom {
    margin: 0;
    font-weight: bold;
    font-size: 17px;
}
  .card-text {
    font-size: 17px;
  }

  .botcastImage {
    width: 180px;
    height: 180px;
    border-radius: 2%;
    object-fit: cover;
  }

/* SubscriptionStyles.css */
.subscription-d-box {
    background-color: #222222;
    color: #fff;
    margin: 5px;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    position: relative;
    height: 125px;
}

.subscription-d-box::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0px;
    height: 4px;
    background-color: #E33BC3;
}

.box-title {
    font-size: 18px;
    opacity: 1.7;
    text-align: left; 
}

.box-value {
    font-size: 25px;
    font-weight: bold;
}

/* Subscription second section */
.section-container {
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.section-title {
    color: #cd26ae;
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
}

.piechart-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px; 
}

.data-image { }

.data-number {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}

.legend-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 5px;
}
.white-space-normal {
    white-space: normal;
}
.chartDataScroll {
    height: 320px;
    overflow-y: auto;
    margin-top: 40px;
}
.legend-color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 10px;
}

.legend-text {
    display: flex;
    align-items: center;
    font-size: 18px; 
    color: #222222;
    font-weight: 600;
}

.table-scroll {
    max-height: 287px;
    overflow-y: auto;
    /* min-height: 288px; */
}
.table-scroll thead th {
	position: sticky;
	top: 0px; 
}

/* Sidebar and Header sticky */
#sidebar {
    position: sticky;
    top: 0; 
    height: 100vh; 
    overflow-y: auto; 
    z-index: 1000;
}
.bg-dark {
    background-color: #222222 !important;
}

.botcast-analytics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
}

.analytics-box {
    flex: 0 0 18%; 
    background-color: #222222;
    color: #fff;
    padding: 15px; 
    box-sizing: border-box;
    margin-bottom: 20px; 
    text-align: center; 
    border-radius: 5px;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    position: relative;
    height: 125px;
}

.analytics-box::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0px;
    height: 4px;
    background-color: #E33BC3;
}
.analytics-box .box-title, .analytics-box .box-value {
    display: block; 
    margin-bottom: 10px; 
    font-size: 16px;
    opacity: 1.7;
    text-align: left; 
}

.analytics-box .box-value {
    font-weight: bold;
    font-size: 22px; 
}

/* Pie chart blur */
.piechart-blur {
	height: 362px;
	filter: blur(10px);
	pointer-events: none;
	user-select: none;
}
.pieChartBlurText {
    position: absolute;
    width: 100%;
    /* left: 130; */
    justify-content: center ;
    z-index: 9;
    padding: 30px;
    font-size: 18px;
    font-weight: 700;
    color: #202020;
    display: flex;
    align-items: center;
    height: 100%;
    
}
.selectCustom .basic-single-select .select__control {
    width: 100%;
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #222222;
    background-clip: padding-box;
    border: 1px solid #E33BC3;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

/* Page not found */
.innerBG-404 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: #000;
}
.font26Primary {
    font-size: 26px;
    color: #E33BC3;
    font-weight: 600;
}
.btnOrange {
    padding: 7px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #E33BC3;
    color: #fff;
    border: 0;
}
.btnOrange:hover,
.btnOrange:active,
.btnOrange:focus-visible {
    background: rgba(255, 131, 46, 0.8) !important;
    color: #fff;
}

.btnOrange:disabled {
    background: var(#E33BC3);
    color: #fff;
    cursor: not-allowed;
}

.btnOrange.lg {
    padding: 30px 40px;
    width: 260px;
}

.btnOrange.lg-premium {
    padding: 30px 25px;
    width: 260px;
}
/* Page not found css */